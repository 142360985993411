<template>
  <div id="login-page">
    <div class="login-content">
      <div class="content-container">
        <b-row
          class="login-form justify-content-end"
          no-gutters
          align-v="end">
          <b-col
            class="my-auto"
            cols="12"
            lg="7"
            xl="6">
            <div class="form-container">
              <b-form @submit.prevent="login">
                <b-input-group prepend="Email">
                  <b-form-input
                    id="emailUsername"
                    type="text"
                    aria-describedby="emailUsername-input"
                    autocomplete="off"
                    placeholder="Email or Username"
                    v-model.trim="$v.form.emailUsername.$model"
                    :state="!$v.form.emailUsername.$error"
                    @input="$v.form.emailUsername.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback id="emailUsername-input">
                    <span v-if="!$v.form.emailUsername.required">
                      Email or Username is required.
                    </span>
                    <span
                      v-if="!$v.form.emailUsername.isEmailRegistered &&
                        $v.form.emailUsername.required">
                      Email or Username is unregistered.
                    </span>
                  </b-form-invalid-feedback>
                </b-input-group>

                <b-input-group prepend="Password">
                  <b-form-input
                    id="password"
                    type="password"
                    aria-describedby="password-input"
                    autocomplete="off"
                    placeholder="Password"
                    v-model.trim="$v.form.password.$model"
                    :state="!$v.form.password.$error"
                    @input="$v.form.password.$touch()"
                  ></b-form-input>
                  <b-form-invalid-feedback id="password-input">
                    <span v-if="!$v.form.password.required">
                      Password is required.
                    </span>
                    <span
                      v-if="!$v.form.password.minLength &&
                        $v.form.password.required">
                      Password must have at least
                      {{$v.form.password.$params.minLength.min}} characters
                    </span>
                  </b-form-invalid-feedback>
                </b-input-group>

                <b-row class="justify-content-center btn-container no-gutters">
                  <b-col class="ml-2 mr-2" cols="12" md="3" lg="3" xl="3">
                    <b-button
                      type="submit"
                      size="sm"
                      block
                      :disabled="$v.form.$invalid">
                      Login
                    </b-button>
                  </b-col>
                  <b-col class="ml-2 mr-2" cols="12" md="3" lg="3" xl="3">
                    <router-link to="/sign-up">
                      <b-button
                        size="sm"
                        block>
                        Register
                      </b-button>
                    </router-link>
                  </b-col>
                  <b-col class="ml-2 mr-2" cols="12" md="3" lg="3" xl="3">
                    <router-link to="/forgot-password">
                      <b-button
                        size="sm"
                        block>
                        Forgot Password
                      </b-button>
                    </router-link>
                  </b-col>
                </b-row>

                <p class="text-center or">OR</p>
                <SocialMedia />
              </b-form>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <AppFooter class="footer" />
    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import _ from 'lodash';
  import { mapGetters } from 'vuex';
  import { required, minLength } from 'vuelidate/lib/validators';
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    head : {
      title : {
        inner      : 'Login',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        loading : false,
        form    : {
          emailUsername : null,
          password      : null,
        },
        isEmailRegistered : true,
      }
    },
    computed : {
      ...mapGetters({
        authStatus : 'auth/authStatus',
      }),
    },
    components : {
      'AppFooter'   : () => import('@/components/layout/AppFooter'),
      'AppLoader'   : () => import('@/components/layout/AppLoader'),
      'SocialMedia' : () => import('@/components/common/SocialMediaButtons'),
    },
    watch : {
      'form.emailUsername'() {
        this.isEmailRegistered = true;
        this.checkEmailUsername();
      },
      authStatus(val) {
        if (val)
          this.loading = (val === 'loading') ? true : false;
      },
    },
    methods : {

      /**
       * Login Account
       */
      login() {
        this.$store.dispatch('auth/login', {
          email    : this.form.emailUsername,
          password : this.form.password,
        });

        this.$analytics.fbq.event('login', {
          'email'    : this.form.emailUsername,
          'platform' : 'website',
        });
      },

      /**
       * Check if Email/Username is already registered
       */
      checkEmailUsername : _.debounce(function () {
        this.$http.get('api/user/email', {
          params : {
            email : this.form.emailUsername,
          },
        }).then(response => {
          this.isEmailRegistered = response.data;
        }).catch(() => {
          this.$v.form.emailUsername.$touch();
        });
      }, 500),
    },
    mounted() {
      this.$analytics.fbq.event('PageView', {
        'page'     : 'login',
        'platform' : 'website',
      });
    },
    validations : {
      form : {
        emailUsername : {
          required,
          registeredEmail() {
            return this.isEmailRegistered;
          },
        },
        password : {
          required,
          minLength : minLength(5),
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #login-page {
    background-image: $login-bg;
    background-attachment: fixed;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .form-container {
    padding: 2rem;
    background-color: #342aff;
    border: 10px solid $login-orange;
    border-radius: 1rem;
    box-shadow: 5px 5px 30px rgba(0,0,0, 0.8);
  }

  .or {
    margin-top: 1rem;
    color: #fff;
    font-family: "WickedMouse" !important;
    -webkit-text-stroke: 1px $login-purple;
  }

  .form-control {
    height: auto;
  }

  .input-group-text {
    width: 10em;
    background-color: $login-orange !important;
    color: #fff;
    font-family: "WickedMouse";
    font-size: 1em;
    -webkit-text-stroke: 1px $login-purple;
  }
  .invalid-feedback {
    margin-left: 14.5em;
    color: #fff !important;
  }

  .btn-container {
    .btn {
      background-color: $login-orange;
      border: 4px solid $login-purple;
      border-radius: 1.5rem;
      font-family: "WickedMouse" !important;
      font-size: 0.8em !important;
      -webkit-text-stroke: 1px $login-purple;

      &:hover {
        box-shadow: 2px 2px 10px rgba(0,0,0, 0.2);
      }
    }
  }

  a {
    text-decoration: none !important;
  }

  .hide {
    display: none;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .login-content {
      padding-top: 6rem;
      padding-bottom: 1rem;
      .login-form {
        height: 100vh;
      }
    }
    .input-group-text {
      display: none;
    }
    .invalid-feedback {
      margin-left: 0;
    }
    .btn-container {
      .btn {
        margin-bottom: 0.5rem;
        border-radius: 0.75rem;
        font-size: 0.9em !important;
      }
    }
  }

  /* iPhone 6/7/8 Plus */
  @media only screen
    and (device-width : 414px)
    and (device-height : 736px)
    and (-webkit-device-pixel-ratio : 3) {
    .login-content {
      .login-form {
        height: calc(100vh - 9.4em);
      }
    }
  }

  /* iPhone X and Xs Max */
  @media only screen
    and (min-device-width: 375px)
    and (min-device-height: 812px)
    and (-webkit-device-pixel-ratio: 3)
    and (orientation: portrait) {
    .login-content {
      .login-form {
        height: calc(100vh - 9.3em);
      }
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .login-content {
      padding-top: 7rem;
      padding-bottom: 3rem;
      .login-form {
        height: calc(100vh - 3.3em);
      }
    }
    .btn-container {
      .btn {
        margin-bottom: 0.5rem;
        border-radius: 0.75rem;
        font-size: 1em !important;
      }
    }
    .invalid-feedback {
      margin-left: 10rem;
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .login-content {
      .login-form {
        height: calc(100vh - 4.9em);
      }
    }
    
    .form-container {
      margin-bottom: 0;
    }
    .btn-container {
      .btn {
        height: 100%;
        border-radius: 1rem;
      }
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .login-content {
      .login-form {
        height: calc(100vh - 5em);
      }
    }
    .btn-container {
      .btn {
        // height: 100%; //85%;
        font-size: 0.8em !important;
      }
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .login-content {
      padding-top: 0;
      padding-bottom: 0;
      background-image: $pj-bg;
      background-attachment: fixed;
      background-position: left bottom;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .form-container {
      margin-top: 5rem;
      border: 7px solid $login-orange;
    }
    .btn-container {
      .btn {
        height: 100%; //85%;
        font-size: 0.8em !important;
      }
    }
  }
  @media only screen and (min-width: $special) {
    .login-content {
      padding-top: 0;
      .login-form {
        padding-top: 3rem;
        height: calc(100vh - 4.2em);
      }
    }
    .form-container {
      margin-top: 2rem;
      border: 7px solid $login-orange;
    }
    .btn-container {
      .btn {
        height: 100%;;
        font-size: 0.8em !important;
      }
    }
  }

  @media only screen and (min-width: $xl2-min) {
    .login-content {
      .login-form {
        height: calc(100vh - 3.7em);
      }
    }
    .form-container {
      margin-top: 0;
      border: 10px solid $login-orange;
    }
    .btn-container {
      .btn {
        height: 100%;
        font-size: 0.85em !important;
      }
    }
    .invalid-feedback {
      margin-left: 14rem;
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .login-content {
      .login-form {
        height: calc(100vh - 4.6em);
      }
    }
    .btn-container {
      .btn {
        height: 100%;
        font-size: 1em !important;
      }
    }
  }
</style>